import React from "react";
import CustomSlider from "../../../components/CustomSlider";
import { careerSlider } from "../../../config/sliders";
import { Button, Col, Row } from "react-bootstrap";
import { VacancyType } from "..";

interface YouthCardProps {
  data: VacancyType[];
  onClickCard: (id: string) => void;
}

const CareerCard = ({ data, onClickCard }: YouthCardProps) => {
  return (
    <CustomSlider selector=".careerSlider" settings={careerSlider}>
      {data?.map((item, index) => {
        return (
          <div key={item.id} className="careerSlider">
            <Row>
              <Col xs={12}>
                <h3>{item.title}</h3>
              </Col>
              <Col xs={12}>
                <p>{item.summary}</p>
              </Col>
              <Col>
                <Button onClick={() => onClickCard(item.id)}>Apply Now</Button>
              </Col>
            </Row>
          </div>
        );
      })}
    </CustomSlider>
  );
};

export default CareerCard;
