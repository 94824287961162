import React from "react";
import CustomSlider from "../../../components/CustomSlider";
import { testimonialSlider } from "../../../config/sliders";
import Shehan from "../../../assets/images/succcess_stories/Shehan_Chamika_Aberathna.jpg";
import Madhuri from "../../../assets/images/succcess_stories/Madhuri_Dilshani.jpg";
import Praneeth from "../../../assets/images/succcess_stories/Praneeth_Shalanka.jpg";
import Charuka from "../../../assets/images/succcess_stories/Charuka_Lakitha.jpg";
import Vinothkumara from "../../../assets/images/succcess_stories/M_Vinothkumar.jpg";
import Virajith from "../../../assets/images/succcess_stories/Virajith_nayanranga.jpg";
import Prasadani from "../../../assets/images/succcess_stories/Prasadani_Perera.jpg";
import Mohanathas from "../../../assets/images/succcess_stories/mohanathas_thimojan.jpg";
import Nawarathnararaja from "../../../assets/images/succcess_stories/nawarathnararaja_komathi.jpg";
import Dhanushan from "../../../assets/images/succcess_stories/gk_dhanushan.jpg";
import Nipuni from "../../../assets/images/succcess_stories/nipuni-nikeshala.jpg";
import Dewni from "../../../assets/images/succcess_stories/dewni-perera.jpg";

const Testimonials = () => {
  return (
    <section className="testimonial_section">
      <div className="container">
        <div className="row  heading-row" data-aos="fade-down">
          <h2>What Student Says</h2>
        </div>
        <div className="row row-clr testimonial_slider" data-aos="fade-down">
          <CustomSlider
            selector=".testimonial_slider"
            settings={testimonialSlider}
          >
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Shehan})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Shehan Chamika Aberathna</h3>
                <h4>Online Entrepreneur</h4>
                <p>
                  In life, there are dreams that will soon come true... I've
                  pondered a lot about when I would be able to drive my own car.
                  Today, as I turned 21, I fulfilled that dream using my own
                  money. No matter how poor one is at birth, they can triumph in
                  life. eClub Business College has brought my dreams to life. I
                  am immensely grateful to eClub Business College for guiding me
                  to this point.
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            {/* <!-- slider-inner-wrapper --> */}
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Madhuri})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>W.Madhuri Dilshani Bolonghe</h3>
                <h4>Freelancing</h4>
                <p>
                  You can't succeed without taking a risk. This journey began
                  after leaving my job. I express heartfelt gratitude to Eclub
                  Business College for everything I have achieved in my life
                  today.
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            {/* <!-- slider-inner-wrapper --> */}
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Praneeth})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Praneeth Shalanka</h3>
                <p>
                  The eClub Business College has been a significant achievement
                  in my life today. Learning about Online Business has been a
                  great knowledge that I've gained recently. Thank you, eClub!
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            {/* <!-- slider-inner-wrapper --> */}
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Charuka})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Charuka Lakitha Fernando</h3>
                <p>
                  "Today, eClub Business College has been a major achievement in
                  my life. Learning about Online Business has provided me with
                  invaluable knowledge that I never knew before. Thank you,
                  eClub."
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            {/* <!-- slider-inner-wrapper --> */}
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Vinothkumara})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>M.Vinothkumara</h3>
                <h4>course consulting officer</h4>
                <p>
                  Thank you so much, Eclub. I am a Tamil medium student, and I
                  learned Digital Wealth Guide at Eclub Business College. Now, I'm
                  earning over $100 USD per month, thanks to all my lecturers
                  and Eclub Business College.
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Virajith})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Virajith nayanranga</h3>
                <h4>Student</h4>
                <p>
                  Eclub කියන්නේ මට මගේ Dream වලට යන්න හම්බ වුනු හොදම තැන.මම ebay
                  course එකට join වෙලා දැනට අවුරුදු 3 ක් විතර වෙනවා. මම උපාධියක්
                  කරන ගමන් තමයි මේ course එකට සම්බන්ද වුනේ. මම දැනට හොද Income
                  එකක් ගන්නවා ebay වලින්. අවසාන මාස කීහිපයේ පමණක් $10000 ක්
                  හොයන්න පුලුවන් උනා..❤️❤️ Thank you Eclub❤️❤️❤️
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            {/* <!-- slider-inner-wrapper --> */}
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Prasadani})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Prasadani Perera</h3>
                <h4>Freelancer</h4>
                <p>
                  2020 අවුරුද්දේ eClub Business College එකට eBay පාඨමාලාව හරහා
                  student කෙනෙක් විදියට සම්බන්ධ වෙලා, සහ 2023 Fiverr පාඨමාලාව
                  හරහා අද වෙනකොට මගේම කියලා මිලියන හතරකට අධික වත්කම් නිර්මාණය
                  කරගන්න මට හැකි වෙලා තියෙනවා..කෙටි කාලයකින් සාර්ථකත්වයට යන්න
                  පාර පෙන්නුවට eClub Business College එකට සහ මාව ආයතනයට සම්බන්ධ
                  කරපු Yasiru Fernando අයියට ස්තූති කරනවා.
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Mohanathas})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Mohanathas Thimojan</h3>
    
                <p>
                எனது பெயர் மோ. திமோஜன் நான் கடந்த நவம்பர் மாதம் ELUB BUSINESS COLLEGE இல் இணைந்தேன் 28 வகுப்புகள் முடிவடைந்ததை தொடர்து 2 வாரமாக trading செய்து வருகின்றேன். இது வரை அதிகளவு achieve பன்னி உள்ளேன். இதற்கு உதவிய ஆசிரியர்களுக்கும் eClub Business College கும் நன்றி
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Nawarathnararaja})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Nawarathnararaja komathi</h3>
    
                <p>
                Digital Wealth Guide, படிப்பதற்கு சிறந்த இடம்.நான் இப்போது சம்பாதித்து கொண்டிருக்கிறேன்.நன்றி eClub. இந்த இடம் உங்கள் வாழ்க்கையை சிறந்த இடத்திற்கு கொண்டு செல்லும்.எனது அனைத்து விரிவுரையாளர்களுக்கும் eClub ப்பிற்கும் நன்றி

          </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Dhanushan})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>GK Dhanushan</h3>
    
                <p>
                எனது பெயர் தனுஷன் நான் eClub மூலமாக மிகவும் சுலபமான முறையில் Digital Wealth Guide படித்தேன் அத்தோடு  Crypto  தொடர்பான அனைத்து விதமான  சந்தேகங்களையும் எனக்கும் தெளிவு படுத்தினார்கள் எங்களுடைய தமிழ் மொழி lecturers  அதனால்  நான் இன்று  Digital Wealth Guide மூலமாக பணம் சம்பாதித்து கொண்டிருக்கிறேன்   அதற்கு  காரணமாக இருந்த எங்களுடைய தமிழ் மொழி lecturers அவர்களுக்கும் eClub Business College கும் என்னுடைய  நன்றிகள்
                </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Dewni})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Dewni</h3>
    
                <p>Fiverr marketplace එක තියා online business කියන්නේ මොකක්ද කියලවත් හරිහමන් knowledge එකක් නැතුව ආපු මම, අද වෙද්දි fiverr එකේ seller කෙනෙක් විදියට මගේම business එකකින් earn කරන තත්වෙට ඇවිත් ඉන්නවා</p>
                <p>eclub එකට ආව මුල් දවසේ සිට අද වෙනතුරුත් අපිට මාරම support එකක් දෙන Fiverr lecturer panel එක ඇතුළු eclub crew එකට🙏♥️  ඒ වගේම වචනෙකින් හෝ උදව් කරපු අනිත් හැමෝටමත් ගොඩක් ස්තූතියි♥️</p>

       
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
            <div className="slider-inner-wrapper">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${Nipuni})`,
                }}
              ></div>
              {/* <!-- image-wrapper --> */}
              <div className="contnet-wrapper">
                <h3>Nipuni Nikeshala</h3>
    
                <p>
                මම eClub Business College  එකත් එක්ක සම්බන්ධ වෙලා Freelance Masters course එක කරලා Fiverr පටන්ගෙන දැනට මාස 5ක් පමණ වෙනවා. මේ වන විට මම  Fiverr  හරහා $200කට ආසන්න මුදලක් earn  කරලා තියෙනවා. මේ සදහා මට college  එකේ Freelance Masters Crew එක මට ගොඩාක් උදව් කරනවා. ඒ වගේම මාව College  එකට සම්බන්ධ කෙරුව CCO Yasiru Dilshan ටත් eClub Business College  එකටත්  මේ ගමන සාර්ථකව යන්න උදව් කරනවට ගොඩාක් ස්තුතියි.

               </p>
              </div>
              {/* <!-- contnet-wrapper --> */}
            </div>
          </CustomSlider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
