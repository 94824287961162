import React, { useContext, useEffect, useRef, useState } from "react";
import Search from "../../assets/images/header/search.svg";
import SearchBar from "../SearchBar";
import AnimateMenu from "./menu/AnimateMenu";
import { ArrowRight, ArrowRightShort } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo_white.svg";
import { NavBarContext } from "../../assets/contexts/navBarContext";
import TopLayer from "./topLayer";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface NavLink {
  name: string;
  to: string;
  id: number;
  courseTypes?: CourseType[];
  children?: any[];
}

interface CourseType {
  id: number;
  courseCategory: string;
  courseId: string;
  courseHeader: string;
  courseName: string;
}

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [display] = useContext(NavBarContext);
  const [isDisplay, setIsDisplay] = useState<any>("block");

  const navWrapperRef = useRef(null);
  const navHiderRef = useRef(null);

  useEffect(() => {
    if (display.hasOwnProperty("navHide")) setIsDisplay(display.navHide);
  }, [display]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const navigateToCourseDetails = (path) => {
    navigate(path);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (prevScrollPos > currentScrollPos && currentScrollPos < 700) {
      navWrapperRef.current.style.top = "0";
      navWrapperRef.current.style.paddingTop = "0";
      navHiderRef.current.style.top = "0";
      navHiderRef.current.style.paddingTop = "0";
    } else {
      navWrapperRef.current.style.top = "-100px";
      navWrapperRef.current.style.paddingTop = "5px";
      navHiderRef.current.style.top = "-100px";
      navHiderRef.current.style.paddingTop = "5px";
    }

    setPrevScrollPos(currentScrollPos);
  };

  const coursesSection = navLinks.find((link) => link.to === "course_details");
  const courseTypes = coursesSection ? coursesSection.courseTypes || [] : [];

  // Group courses by category
  const groupedCourses: Record<string, CourseType[]> = {};
  courseTypes.forEach((course) => {
    if (!groupedCourses[course.courseCategory]) {
      groupedCourses[course.courseCategory] = [];
    }
    groupedCourses[course.courseCategory].push(course);
  });
  const categories = Object.entries(groupedCourses);

  return (
    <div
      id="nav-header"
      ref={navHiderRef}
      className={
        isDisplay === "block"
          ? "d-block customHeaderBlock"
          : "d-none customHeaderNone"
      }
    >
      <SearchBar />
      <section
        id="nav-wrapper"
        ref={navWrapperRef}
        className={
          isScrolled
            ? "fixed-top d-none d-lg-block header-section scrollNav"
            : "fixed-top d-none d-lg-block header-section scrollNav"
        }
      >
        <TopLayer />
        <nav className="">
          <div className="container d-flex flex-wrap bottom-container">
            <ul
              className={`nav nav-pills nav-fillactiveKey=${location.pathname} `}
            >
              {navLinks.map(({ name, to, id, children }) => {
                let isActive =
                  location.pathname === `/` + to ||
                  location.pathname.includes(`/` + to + `/`);
                if (id == 7) {
                  return (
                    <li
                      key={coursesSection.id}
                      className="align-self-center nav-item menu-dropdown"
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className={isActive ? "nav-link active" : "nav-link"}
                        >
                          {coursesSection.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ width: "700px" }}>
                          <div className="row">
                            {categories.map(([category, courses], index) => (
                              <div
                                key={category}
                                className={`col-md-${index < 2 ? "6" : "12"}`}
                              >
                                <div className="dropdown-header-custom">
                                  {category}
                                </div>
                                <ul>
                                  {courses.map((course) => (
                                    <li key={course.id}>
                                      <Dropdown.Item
                                        className="dropdown-item-custom"
                                        onClick={() =>
                                          navigateToCourseDetails(
                                            `/course_details/${course.courseId}`
                                          )
                                        }
                                      >
                                        <ArrowRightShort /> {course.courseName}
                                      </Dropdown.Item>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  );
                } else if (id == 13) {
                  return (
                    <li
                      key={id}
                      className="align-self-center nav-item menu-dropdown"
                    >
                      <Dropdown id={id.toString()}>
                        <Dropdown.Toggle
                          id="dropdown-basic1"
                          className={isActive ? "nav-link active" : "nav-link"}
                        >
                          {name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="row px-2">
                            <ul>
                              {children &&
                                children.map((item, id) => (
                                  <li key={item.id}>
                                    <Dropdown.Item
                                      className="dropdown-item-custom"
                                      onClick={(e) => {
                                        // e.preventDefault();
                                        navigateToCourseDetails(
                                          `/media/${item.categoryCode}`
                                        );
                                      }}
                                    >
                                      <ArrowRightShort /> {item.category}
                                    </Dropdown.Item>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  );
                } else {
                  return (
                    <li role="button" key={id} className="nav-item">
                      <a
                        onClick={() => navigateToCourseDetails(`/${to}`)}
                        className={isActive ? "nav-link active" : "nav-link"}
                      >
                        {name}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </nav>
      </section>
      <AnimateMenu />
    </div>
  );
};

const navLinks: NavLink[] = [
  { name: "Home", to: "", id: 5 },
  { name: "About Us", to: "about", id: 6 },
  {
    name: "Courses",
    to: "course_details",
    id: 7,
    courseTypes: [
      /*  {
        id: 1,
        courseCategory: "Online Business Education Courses",
        courseId: "ESG",
        courseHeader: "Online Business Education Courses",
        courseName: "eBay Selling Guide",
      },
    {
        id: 2,
        courseId: "PWS",
        courseCategory: "Personal Development Workshops",
        courseHeader: "Personal Development Workshops",
        courseName: "Passionpreneurs’ Workshop (PWS)",
      },
      {
        id: 3,
        courseId: "TSG",
        courseCategory: "Personal Development Workshops",
        courseHeader: "Personal Development Workshops",
        courseName: "The Success Guide",
      },
      {
        id: 4,
        courseCategory: "Online Business Education Courses",
        courseId: "FrMst",
        courseHeader: "Online Business Education Courses",
        courseName: "Freelance Masters",
      },*/
      {
        id: 5,
        courseCategory: "Online Business Education Courses",
        courseId: "DWG",
        courseHeader: "Online Business Education Courses",
        courseName: "Digital Wealth Guide",
      },
      {
        id: 6,
        courseCategory: "Education Courses in IT Field",
        courseId: "certificate-course",
        courseHeader: "Education Courses in IT Field",
        courseName: "Graphic Designing",
      },
   /*    {
        id: 7,
        courseCategory: "Education Courses in IT Field",
        courseId: "ICT",
        courseHeader: "Education Courses in IT Field",
        courseName: "Computer Applications Assistant",
      },
     {
        id: 10,
        courseCategory: "Education Courses in IT Field",
        courseId: "CIT",
        courseHeader: "Education Courses in IT Field",
        courseName: "Certificate in IT",
      },*/
      {
        id: 8,
        courseCategory: "Language Courses",
        courseId: "ACIE",
        courseHeader: "Language Courses",
        courseName: "Advanced Certificate in English Course (TalkMe English)",
      },
      {
        id: 9,
        courseCategory: "Language Courses",
        courseId: "IELTS",
        courseHeader: "Language Courses",
        courseName: "IELTS",
      },
      {
        id: 11,
        courseCategory: "Language Courses",
        courseId: "EPS TOPIK",
        courseHeader: "Language Courses",
        courseName: "Korean Language Course - EPS TOPIK",
      },
      {
        id: 12,
        courseCategory: "Language Courses",
        courseId: "JLPT N5",
        courseHeader: "Language Courses",
        courseName: "Japanese Language Course – JLPT N5",
      },
      {
        id: 13,
        courseCategory: "Language Courses",
        courseId: "JFT",
        courseHeader: "Language Courses",
        courseName: "Japanese Language Course – JFT",
      },
    ],
  },
  // { name: "Free courses", to: "free_courses", id: 8 },
  { name: "Scholarships", to: "scholarships", id: 9 },
  { name: "Life at eClub", to: "life_at_club", id: 10 },
  { name: "eClub youth", to: "eclub_youth", id: 11 },
  { name: "News & Events", to: "news", id: 12 },
  {
    name: "Media",
    to: "media",
    id: 13,
    children: [
      {
        id: 1,
        category: "Gallery",
        categoryCode: "gallery",
      },
      {
        id: 2,
        category: "Video",
        categoryCode: "video",
      },
    ],
  },
  { name: "Contact Us", to: "contact_us", id: 14 },
];
