import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import Banner from "../contactUs/Components/banner";
import { Col, Container, Row, Stack } from "react-bootstrap";
import MidBanner from "../home/components/midBanner";
import TeamImg from "../../assets/images/career/team1.jpg";
import Overlay from "../../assets/images/career/Overlay22.png";
import Image2 from "../../assets/images/career/Image2.png";
import Image3 from "../../assets/images/career/Image3.png";
import Image4 from "../../assets/images/career/Image4.png";
import CareerCard from "./Components/CareerCard";
import { useToast } from "../../assets/contexts/toast";
import axiosInstance from "../../api/axiosConfig";
import CareerByID from "./Components/CareerById";
import { useNavigate } from "react-router-dom";

export interface VacancyType {
  id: string;
  title: string;
  summary: string;
  start_date: string;
  end_date: string;
}

const CareerPage = () => {
  const navigate = useNavigate();
  const { showErrorToast } = useToast();
  const [vacancies, setVacancies] = useState<VacancyType[]>([]);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      const response = await axiosInstance.get("/api/vacancies");
      console.log("res", response);
      if (response && response.data && response.data.results) {
        let apiResponse = response.data.results;
        setVacancies(apiResponse);
      }
    } catch (error) {
      showErrorToast("Failed to fetch events. Please try again.");
    } finally {
    }
  };

  const onclickCard = (id: string) => {
    if (id) {
      navigate(`/career_by_id/${id}`);
    }
  };

  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <section
          className="common_banner"
          style={{
            backgroundImage: `url(${Overlay})`,
          }}
        >
          <div className="container">
            <ol className="breadcrumb breadcrumb-type-1">
              <li>
                <a href="/">Home</a>
              </li>
              <li className="active">&ensp;/&ensp;Join Us</li>
            </ol>
            <Row className="text-center">
              <Col xs={12}>
                <h2>Empowering Youth</h2>
              </Col>
              <Col xs={12}>
                <h5 className="headerPara mt-3">
                  Fuel your career passion at eClub Business College by
                  exploring transformative opportunities to empower minds,
                  foster growth, and revolutionize the future of education. Join
                  us and be a trailblazer in reshaping the educational
                  landscape! eClub Careers is your gateway to a world of
                  possibilities and a launchpad for your professional
                  aspirations. Become part of a prestigious institution that not
                  only prioritizes innovative education but also cultivates a
                  thriving community of forward-thinking individuals. Your
                  journey toward success starts here!
                </h5>
              </Col>
            </Row>
          </div>
        </section>
        <Stack className="mt-5 mb-5" gap={5}>
          <Container className="career">
            <Row>
              <Col md={6}>
                <Col xs={12}>
                  <h2>Join Our Team and Shape the Future Together!</h2>
                </Col>
                <Col xs={12}>
                  <p>
                    The role involves preparing and delivering lectures,
                    developing course materials, assessing student performance,
                    conducting research, and contributing to the academic
                    environment of the institution.
                  </p>
                </Col>
              </Col>
              <Col>
                <img src={Image2} className="img-fluid" alt="Image2" />
              </Col>
            </Row>
          </Container>
          <Row>
            <MidBanner type={"career"} />
          </Row>
          <Container className="career">
            <Stack gap={5}>
              {vacancies && (
                <Row>
                  <Col xs={12}>
                    <h2>Available Positions</h2>
                  </Col>
                  <Col xs={12}>
                    <CareerCard data={vacancies} onClickCard={onclickCard} />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <img src={Image3} className="img-fluid" alt="Image2" />
                </Col>
                <Col>
                  <Col xs={12}>
                    <h2>Join Our Excellence Driven Team</h2>
                  </Col>
                  <Col xs={12}>
                    <p>
                      Driven Team Join Our Excellence Driven Team at eClub
                      Business College and become part of a dynamic group of
                      innovators shaping the future of business education.
                      Together, let's npower the next generation of
                      entrepreneurs and make a lasting impact on the global
                      stage.
                    </p>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Col xs={12}>
                    <h2>Happy and Dynamic Work Culture</h2>
                  </Col>
                  <Col xs={12}>
                    <p>
                      At eClub Business College, we foster a vibrant and
                      energetic work environment where collaboration,
                      innovation, and positivity thrive. Our team is united by
                      shared goals, mutual respect, and a commitment to
                      excellence. We believe in creating a workplace that
                      inspires happiness, encourages creativity, and empowers
                      individuals to grow both personally and professionally.
                      Join us and experience a culture that values teamwork,
                      celebrates achievements, and embraces dynamic change!
                    </p>
                  </Col>
                </Col>
                <Col>
                  <img src={Image4} className="img-fluid" alt="Image2" />
                </Col>
              </Row>
            </Stack>
          </Container>
        </Stack>
      </main>
    </>
  );
};

export default CareerPage;
