import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useToast } from "../../../assets/contexts/toast";
import axiosInstance from "../../../api/axiosConfig";
import { useParams } from "react-router-dom";
import NoData from "../../../assets/images/no-data.png";

interface VacancyIdType {
  id: string;
  title: string;
  summary: string;
  start_date: string;
  end_date: string;
  description: string;
}

const formInitialData = {
  name: "",
  email: "",
  mobile: "",
  message: "",
  file: null,
};

const CareerByID = () => {
  const [formData, setFormData] = useState(formInitialData);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [body, setBody] = useState<VacancyIdType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { showErrorToast, showSuccessToast } = useToast();

  useEffect(() => {
    if (id) {
      getVacancyId(id);
    }
  }, [id]);

  const getVacancyId = async (id: string) => {
    try {
      const response = await axiosInstance.get(`/api/vacancy.php?id=${id}`);
      if (response?.data?.results) {
        setBody(response.data.results);
      }
    } catch (err) {
      showErrorToast("Failed to fetch vacancy. Please try again.");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (selectedFile.size > maxSize) {
        setError("File size exceeds 5MB. Please choose a smaller file.");
      } else {
        setError(null);
        setFormData({
          ...formData,
          [name]: selectedFile,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!error && formData) {
      setLoading(true);
      const data = new FormData();
      data.append("vacancy_id", body?.id || "");
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("mobile", formData.mobile);
      data.append("message", formData.message);
      if (formData.file) {
        data.append("file", formData.file);
      }

      try {
        const res = await axiosInstance.post("/api/vacancy/save", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res?.data?.success) {
          showSuccessToast(
            res?.data?.success ??
              "Congratulations! Your application has been submitted successfully."
          );
        }
      } catch (error) {
        showErrorToast("Data submission failed. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <main>
      <Container className="career_form d-flex justify-content-center mt-5 mb-5">
        {body?.description && (
          <Row>
            <Col xs={12}>
              <div dangerouslySetInnerHTML={{ __html: body.description }} />
            </Col>
            <Col xs={12}>
              <h4>Apply</h4>
              <Form id="application" onSubmit={handleSubmit}>
                <div className="row">
                  <Form.Group
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    controlId="formBasicName"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      required
                      placeholder="Enter your name"
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      required
                      placeholder="Enter your email"
                      onChange={handleChange}
                      value={formData.email}
                    />
                  </Form.Group>
                </div>

                <div className="row">
                  <Form.Group
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    controlId="formBasicMobile"
                  >
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobile"
                      required
                      placeholder="Enter your mobile"
                      onChange={handleChange}
                      value={formData.mobile}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    controlId="formBasicMessage"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type="textarea"
                      name="message"
                      placeholder="Enter a message (optional)"
                      onChange={handleChange}
                      value={formData.message}
                    />
                  </Form.Group>
                </div>

                <div className="row">
                  <Form.Group
                    controlId="formBasicResume"
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-3"
                  >
                    <Form.Label>Upload Your Resume</Form.Label>
                    <Form.Control
                      type="file"
                      name="file"
                      required
                      onChange={handleChange}
                      isInvalid={!!error}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        )}
        {!body?.description && (
          <Row className="mt-5 d-flex text-center">
            <Col xs={12}>
              <img src={NoData} className="img-fluid" alt="NoData" />
            </Col>
            <Col xs={12}>
              <h2>Sorry!. Unable to find the Job</h2>
            </Col>
          </Row>
        )}
      </Container>
    </main>
  );
};

export default CareerByID;
