import React from "react";
import Student from "../../../assets/images/home/4.svg";
import Counter from "../../../components/Counter";

interface MidBanner {
  type?: "career";
}
const MidBanner = ({ type }: MidBanner) => {
  return (
    <section className="mid_banner_section">
      <div className="container">
        <div className="row">
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
            data-aos="fade-left"
          >
            <img src={Student} className="img-responsive" alt="Students" />
            {/* <h3>100k+</h3> */}
            <Counter
              from={0}
              to={150}
              presetString={type == "career" ? "" : "K+"}
            />
            ;<h4>{type == "career" ? "Faculty Members" : "Happy Students"}</h4>
          </div>
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
            data-aos="fade-down"
          >
            <img
              src={require("../../../assets/images/home/5.png")}
              className="img-responsive"
              alt="Courses"
            />
            <Counter
              from={0}
              to={type == "career" ? 600 : 13}
              presetString={"+"}
            />
            ;<h4>{type == "career" ? "Students" : "Courses"}</h4>
          </div>
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
            data-aos="fade-right"
          >
            <img
              src={require("../../../assets/images/home/6.png")}
              className="img-responsive"
              alt="Persons"
            />
            <Counter
              from={0}
              to={type == "career" ? 18 : 100}
              presetString={"+"}
            />
            ;<h4>{type == "career" ? "Programmes" : "Resource Persons"}</h4>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MidBanner;
